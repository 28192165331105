@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;400;500;700&display=swap");
body {
  font-family: "Inter", sans-serif;
}

.showonprint{
   display:none;
}

@media print {
  .hideonprint{
    display:none;
  }
  .showonprint{
    display:block;
  }
}
