.schedule {
  display: grid;
  background-image: linear-gradient(
    90deg,
    #ffffff 25%,
    #fafafa 25%,
    #fafafa 50%,
    #ffffff 50%,
    #ffffff 75%,
    #fafafa 75%,
    #fafafa 100%
  );
  background-size: 20px 20px;
  grid-template-rows: [tracks] auto [Mon] 1fr [Tue] 1fr [Wed] 1fr [Thr] 1fr [Fri] 1fr [Sat] 1fr;
  grid-template-columns: auto auto auto auto;
  width: "100%";
}

.track-slot {
  display: none;
  font-weight: bold;
  font-size: 0.8em;
  border: 2px solid #778899;
  text-align: center;
  height: 70px;
  background-color: white;
}

@media screen and (max-width: 700px) {
  .track-slot {
    font-size: 0.6em;
  }
}

.time-slot {
  grid-column: times;
  display: inline-block;
  height: 25px;
  font-weight: bold;
  font-size: 1em;
  background-color: white;
  text-align: center;
  padding: 0 !important;
  border: 2px solid #778899;
  width: 100px;
}

.track-slot,
.time-slot {
  display: block;
  padding: 10px 5px 5px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: rgba(255, 255, 255, 0.9);
}

@media screen and (max-width: 700px) {
  .time-slot {
    display: none;
  }
}
/* Small-screen & fallback styles */
.session {
  margin-bottom: 1em;
}

@supports (display: grid) {
  .session {
    margin: 0;
  }
}


@media print {
  .schedule{
    background: none;
  }
}