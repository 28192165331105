@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;700&family=Inter:wght@400;500;700&display=swap");

.table {
  font-family: "Inter", Courier, monospace;
}

.table,
.table th,
.table td {
  padding: 4px 8px;
  border: solid 1px #777;
}

.table th {
  font-weight: bold;
}

@media print {
  .table,
  .table th,
  .table td {
    font-size: large;
  }
}
