.containerbox {
    padding: 0;
    margin: 0;
}

.paradigmGrid table {
    padding-left: 0;
}

.paddingInput {
    padding-left: 10px;
}