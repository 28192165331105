@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;700&family=Inter:wght@400;500;700&display=swap");

.printcontainer {
  /* A4 595 x 842 pixels */
  width: 65rem;
  position: relative;
  height: 95rem;
}

.header,
.headerText {
  display: flex;
  justify-content: center;
  padding: 0;
}
.headerText {
  font-size: xx-large;
  font-weight: 600;
}

.logo {
  width: 100%;
}
@media print {
  * {
    -webkit-print-color-adjust: exact;
  }
}

.headerLogo {
  background-image: url("../../../../assets/images/header.png") !important;
  height: 210px;
  width: 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  padding-bottom: 12px;
}

i {
  font-size: 25px;
}

.studentInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  font-size: x-large;
}

.pPrint {
  font-size: 25px;
  font-weight: normal;
  font-family: "Inter", Courier, monospace;
  letter-spacing: 1px;
}

.logoheader {
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  font-size: 1.953rem;
  letter-spacing: 0.3rem;
  font-weight: 700;
  padding-bottom: 45px;
}

.grid--2--colsNotResponsive {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.grid--1--colsNotResponsive {
  display: grid;
  grid-template-columns: 1fr;
}
.grid--university {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  font-size: 1.653rem;
}

.grid--recievedBy {
  grid-column: 1/-1;
}

.padding {
  padding-bottom: 1.2rem;
}

.footer {
  font-size: 12px;
  padding: 12px;
  bottom: 0px;
  width: 100%;
  position: absolute;
  border-top: 2px solid #888;
}
.footerDiv {
  display: flex;
  flex-direction: column;
}
.footerDivAr {
  text-align: right;
}
@media print {
  @page {
    size: A4; /* DIN A4 standard, Europe */
    margin: 1.2rem 2.4rem;
  }
  html,
  body {
    background: #fff;
    overflow: visible;
  }
  body {
    padding-top: 0;
    font-family: "Inter", sans-serif;
  }
}
