.TxtCenter {
  text-align: center;
}

@media screen and (max-width: 700px) {
  .GroupedTable {
    padding: 0;
  }

  .GroupedTable th {
    padding: 0;
    font-size: 12x;
  }

  /* Work on remove !important later */
  .GroupedTable td {
    padding: 0;
    font-size: 12px !important;
  }

  .GroupedTableMobile {
    display: none !important;
  }
}


.Registered {
  background-color: #AFEEEE;
}

.Planned {
  background-color: #FAFAD2;
}

#posofferedIn {
  display: none;
}

.posshowPrerequisite {
  display: none;
}

.posshowOfferedIn {
  display: none;
}

.posshowRelatedTo {
  display: none;
}