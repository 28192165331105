.center {
  margin: auto;
  width: 1024px;
  height: 200px;
  padding: 10px;
}

.logoDiv{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.title{
  font-weight: 700;
  padding: 14px 0 ;
}

.course{
  display: grid;
  grid-template-columns:minmax(100px, 260px) 1fr;
  gap: 10px;
  margin-top: 14px;
}

.googleLink{
  display: flex;
  flex-direction: column;
}

.course input,.course p{
  font-size: 16px;
}

.courseTitle{
font-size: 18px;
}

.btn{
  padding-top:24px;
}

.btns{
  justify-content: space-between;
  text-align: center;
  padding-top: 14px;
}

.input{
  font-weight: 700;
  font-size: 18px;
  background-color: #1971c2;
  border: none;
  color: white;
  padding:8px 16px;
}

.logout{
  background-color: #c23519;
}