.ramadndiv{
    margin-top: 25px;
    padding: 15px 10px 10px 10px;
    background-color:#c92a2a;
}
.ramadndiv p{
font-family:Arial, Helvetica, sans-serif ;
color: white;
font-weight: 700;
    font-size: large;
    text-align: center;
}