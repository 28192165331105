@supports (display: grid) {
  @media screen and (min-width: 700px) {
    .session {
      margin: 0;
    }
  }
}

.session {
  margin-bottom: 1em;
  background-color: gainsboro;
  color: white;
  padding: 0.5em;
  border-radius: 2px;
  font-size: 12px;
  box-shadow: rgba(182, 182, 182, 0.9) 0 2px 2px,
    rgba(63, 63, 63, 0.4) 0 2px 2px;
  height: 60px;
  text-align: center;
}

@media screen and (max-width: 700px) {
  .session {
    font-size: 10px;
  }
}

.sessionConfilt {
  margin-bottom: 1em;
  background-image: linear-gradient(
    to left,
    red,
    rgb(153, 3, 3),
    red,
    rgb(153, 3, 3)
  );
  color: white;
  padding: 0.5em;
  border-radius: 2px;
  font-size: 12px;
  box-shadow: rgba(255, 0, 0, 0.5) 0 2px 2px, rgba(220, 20, 60, 0.6) 0 2px 2px;
  height: 60px;
}

.sessionConfilt .sessionTitle {
  background: white;
  color: red;
  padding: 0.1em;
  border-radius: 2px;
  font-size: 12px;
}

.sessionTitle,
.sessionItem {
  display: block;
}

.sessionTitle {
  margin: 0;
  font-size: 1em;
}
