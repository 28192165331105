.grouptable {
    font-size: small;
    padding: 0;
}

.grouptable th {
    padding: 0;
    padding-left: 1px;
    padding-right: 1px;
    font-weight: normal;
}

@media screen and (max-width: 479px) {
    .title {
        display: none;
    }
}