.container {
  margin: 10px;
}
.advtable {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
}

.advtable tr {
  background-color: white;
  border: 1px solid #ddd;
  padding: 0.35em;
}

.advtable tr:nth-child(even) {
  background-color: #eff3fb;
}
.advtable th,
.advtable td {
  padding: 0.2em;
  text-align: left;
}

.advtable th {
  font-size: 1em;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 600px) {
  .advtable {
    border: 0;
  }

  .advtable caption {
    font-size: 1.3em;
  }

  .advtable thead {
    display: none;
  }

  .advtable tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  .advtable td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: left;
  }

  .advtable td::before {
    /*
    * aria-label has no advantage, it won't be read inside a .advtable
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .advtable td:last-child {
    border-bottom: 0;
  }
}

button {
  text-decoration: underline;
  color: blue;
  font-weight: bold;
  border: none;
  background-color: transparent;
}
